import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from '../components/footer/footer.tsx';
import Navbar from './menu/Navbar.tsx';

const variants = {
  enter: { opacity: 0, x: '100%' },
  center: { opacity: 1, x: 0 },
  exit: { opacity: 1, x: '-100' },
};

export const Page = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <AnimatePresence presenceAffectsLayout mode={'sync'}>
        <motion.div
          key={pathname}
          variants={variants}
          initial={{ x: '100%', opacity: 1 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          <Outlet />
        </motion.div>
      </AnimatePresence>
      <Footer />
    </>
  );
};
